import React from 'react';
import logo from './logo.jpg';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import styled from '@emotion/styled'
import { useTheme } from '@mui/material/styles';


const Header = () => {
    // const theme = useTheme();
    // const red = theme.colors.red;
    // const green = theme.colors.green;
    // const blue = theme.colors.blue;
    // const getStyledLink = (backgroundColor:string, text:string, dest:string) => {
    //     const NavLink = styled(Link)`
    //         background: ${backgroundColor};
    //         color: ${theme.palette.primary.contrastText};
    //         padding: 10px 0;
    //         display: inline-block;
    //         width: 100%;
    //         text-decoration: none;
    //     `;
    //     return <NavLink href={dest} target="_blank" rel="noopener">{text}</NavLink>
    // }
    
    // return (
    //     <Grid container spacing={1} direction="row">
    //         <Grid item xs={6}>
    //             <img src={logo} alt="Logo" />
    //         </Grid>
    //         <Grid item xs={2}>
    //             {getStyledLink(red, 'Site Partners', 'https://www.birddogmarketinggroup.com/site-partner')}
    //         </Grid>
    //         <Grid item xs={2}>
    //             {getStyledLink(green, '717-615-9022', 'tel:717-615-9022')}
    //         </Grid>
    //         <Grid item xs={2}>
    //             {getStyledLink(blue, 'Blog', 'https://www.birddogmarketinggroup.com/blog')}
    //         </Grid>
    //     </Grid>
    // );
    return (<></>);
}

export default Header