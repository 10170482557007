import { ListItemButton, ListItemText, Divider } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from '@emotion/styled';

import ClientListItemProps from './ClientListItemProps';

const ClientListItemButton = styled(ListItemButton)`
  padding-left: 32px;
`;

const ClientListItem = ({
  className,
  client,
  onClientSelect = () => {}
}: ClientListItemProps) => {
  return (
    <>
      <ClientListItemButton
        className={className}
        onClick={() => onClientSelect(client)}
      >
        <ListItemText primary={client.companyName} />
        <ChevronRightIcon />
      </ClientListItemButton>
      <Divider />
    </>
  );
};

const StyledClientListItem = styled(ClientListItem)`
  background-color: ${({ selected }) =>
    selected ? 'rgba(0, 0, 0, 0.15)' : 'white'};
  &:hover {
    background-color: ${({ selected }) =>
      selected ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.05)'};
  }
`;

export default StyledClientListItem;
