import firebase from 'firebase/compat/app';
import { useNavigate } from 'react-router-dom';
import { AppBar as MuiAppBar, Toolbar, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';

import AppBarProps from './AppBarProps';
import useFirebase from '../../hooks/useFirebase';
import useUsersByRole from '../../hooks/useUsersByRole';
import Role from '../../models/Role';

const ToolbarContainer = styled(Toolbar)`
  display: flex;
  align-items: center;
`;

const ToolbarActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
`;

const ToolbarButton = styled(Button)`
  color: white;
`;

const AppBar = ({ className, title, showAdmin }: AppBarProps) => {
  const { currentUser } = useFirebase();
  const { usersByRole: admins } = useUsersByRole(Role.ADMIN);
  const navigate = useNavigate();

  const isAdmin = currentUser && admins[currentUser.uid];

  const onLogOut = () => {
    firebase
      .auth()
      .signOut()
      .finally(() => navigate('/'));
  };

  return (
    <MuiAppBar className={className} position="static" color="secondary">
      <ToolbarContainer variant="dense">
        <Typography variant="h5">{title}</Typography>
        <ToolbarActions>
          {showAdmin && isAdmin && (
            <>
              <ToolbarButton variant="text" onClick={() => navigate('/admin')}>
                Admin Screen
              </ToolbarButton>
              |
            </>
          )}
          <ToolbarButton variant="text" onClick={onLogOut}>
            Log Out
          </ToolbarButton>
        </ToolbarActions>
      </ToolbarContainer>
    </MuiAppBar>
  );
};

export default AppBar;
