import { useContext } from 'react';
import FirebaseContext from '../../components/FirebaseProvider/FirebaseContext';

const useFirebase = () => {
  const value = useContext(FirebaseContext);
  if (!value) {
    throw new Error(
      'You must call useFirebase from inside a FirebaseProvider component.'
    );
  }
  return value;
};

export default useFirebase;
