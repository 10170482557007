import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material/styles';

import ClientListProps from './ClientListProps';
import Client from '../../models/Client';
import ClientListItem from './ClientListItem';

const ListContainer = styled(Paper)`
  height: 100%;
  width: 300px;
`;

const ListHeader = styled(ListItem)(() => {
  const theme = useTheme();
  return `background-color: ${theme.palette.primary.main};`;
});

const HeaderText = styled(ListItemText)`
  color: white;
  & > span {
    font-weight: bold;
  }
`;

const OutterList = styled(List)`
  height: 100%;
`;

const ScrollableList = styled(List)`
  height: calc(100% - 48px);
  overflow: scroll;
`;

const AddClientIcon = styled(AddIcon)`
  color: white;
`;

const ClientList = ({
  clients,
  selectedClientId,
  onClientSelect,
  onClientAdd
}: ClientListProps) => {
  return (
    <ListContainer square variant="outlined">
      <OutterList>
        <ListHeader
          secondaryAction={
            <IconButton edge="end" aria-label="add" onClick={onClientAdd}>
              <AddClientIcon />
            </IconButton>
          }
        >
          <HeaderText primary="Clients" />
        </ListHeader>
        <Divider />
        <ScrollableList disablePadding>
          {clients?.map((client: Client, index: Number) => {
            return (
              client && (
                <ClientListItem
                  key={String(index)}
                  client={client}
                  selected={client.clientId === selectedClientId}
                  onClientSelect={onClientSelect}
                />
              )
            );
          })}
        </ScrollableList>
      </OutterList>
    </ListContainer>
  );
};

export default ClientList;
