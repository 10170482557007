import { getDatabase, ref, set } from 'firebase/database';
import { v4 as uuidV4 } from 'uuid';

import useFirebase from '../useFirebase';

const useAddClient = () => {
  const { app } = useFirebase();

  const addClient = (companyName: string, companyKey: string) => {
    const clientId = uuidV4();
    const db = getDatabase(app);
    // prettier-ignore
    const clientsRef = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/clients/${clientId}`);
    set(clientsRef, { clientId, companyName, companyKey });
  };

  return { addClient };
};

export default useAddClient;
