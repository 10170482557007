import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";
import FirebaseProvider from './components/FirebaseProvider';
import AdminPage from './pages/AdminPage';
import Homepage from './pages/Homepage';
import ReportAdd from "./pages/ReportAdd";
import Role from './models/Role';
import withAuthorization from './components/withAuthorization';

const AdminPageWithAuthorization = withAuthorization(AdminPage, Role.ADMIN);

//TODO: add redirect to "/" if not logged in.
export default function App() {
  return (
    <FirebaseProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/signedIn" element={<Homepage />} />
            <Route path="/admin" element={<AdminPageWithAuthorization />} />
            <Route path="/addReport" element={<ReportAdd />} />
          </Routes>
        </Router>
      </LocalizationProvider>
    </FirebaseProvider>
  );
}
