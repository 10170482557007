import { useState } from 'react';
import {
  Container,
  Button,
  Card,
  TextField,
  Typography,
  styled
} from '@mui/material';

import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 368px;
  min-height: 268px;
  padding: 32px;
  justify-content: center;
  align-items: center;
`;

const StyledTextField = styled(TextField)``;

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isError, setError] = useState(false);

  const { user, logIn } = useAuth();

  if (user && user.email) navigate('/signedIn');

  return (
    <StyledContainer>
      <StyledCard>
        <Typography variant='h5'>Log in to continue</Typography>
        <StyledTextField
          label='Email'
          margin='dense'
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoFocus
          fullWidth
        />
        <StyledTextField
          label='Password'
          type='password'
          margin='dense'
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
        />
        <Button
          variant='contained'
          type='submit'
          onClick={() => logIn(email, password).catch(() => setError(true))}
          fullWidth
        >
          Log In
        </Button>
        {isError && (
          <Typography color='red'>Invalid email or password.</Typography>
        )}
      </StyledCard>
    </StyledContainer>
  );
};

export default SignIn;
