import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';

import AddClientDialogProps from './AddClientDialogProps';

const AddClientDialog = ({ onSubmit, onCancel }: AddClientDialogProps) => {
  const [companyName, setCompanyName] = useState('');
  const [companyKey, setCompanyKey] = useState('');

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>Add Client</DialogTitle>
      <DialogContent>
        <TextField
          label="Company Name"
          margin="dense"
          value={companyName}
          onChange={(event) => setCompanyName(event.target.value)}
          autoFocus
          fullWidth
        />
        <TextField
          label="Company ID"
          margin="dense"
          value={companyKey}
          onChange={(event) => setCompanyKey(event.target.value)}
          autoFocus
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSubmit(companyName, companyKey)}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddClientDialog;
