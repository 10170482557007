import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      red: string;
      green: string;
      blue: string;
      darkGray: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    colors?: {
      red?: string;
      green?: string;
      blue?: string;
      darkGray?: string;
    };
  }
}
const theme = createTheme({
  colors: {
    red: '#BF6246',
    green: '#6FB966',
    blue: '#223160',
    darkGray: '#8C969E'
  },
  palette: {
    primary: {
      main: '#5F83BF',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#1E3163'
    },
    error: {
      main: red.A400
    }
  }
});

export default theme;
