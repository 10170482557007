import { getDatabase, ref, remove } from 'firebase/database';

import useFirebase from '../useFirebase';
import axios, { AxiosRequestConfig } from 'axios';

const useDeleteReport = () => {
  const { app, currentUser } = useFirebase();

  const deleteReport = async (report: any) => {
    const token = await currentUser?.getIdToken(true).catch((error) => {
      // TODO - Error handling.
    });
    const options: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer'
    };

    const year = new Date(report.date).getFullYear();

    axios
      .get(
        `${process.env.REACT_APP_FUNCTIONS_URL}/reports/${report.clientId}/${report.file
          .split('.')
          .slice(0, -1)
          .join('.')}/delete`,
        options
      )
      .then(async (res) => {
        const db = getDatabase(app);
        // prettier-ignore
        const reportRef = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/reports/${report.clientId}/${year}/${report.jobId}`);
        await remove(reportRef);
      })
      .catch((error) => console.error(error));
  };

  return { deleteReport };
};

export default useDeleteReport;
