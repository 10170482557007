// Import FirebaseAuth and firebase.
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import ReportItem from '../ReportItem';
import { reportYears } from '../../types';

interface yearAccordionProps {
  years: reportYears;
  clientId: string;
  isAdmin: boolean;
  showDelete: (report:any) => void;
}
const YearAccordion = (props: yearAccordionProps) => {
  const theme = useTheme();

  const StyledAccordionSummary = styled(AccordionSummary)`
    color: ${theme.palette.primary.contrastText};
    background: ${theme.palette.primary.main};
  `;
  const StyledDivider = styled(Divider)`
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: ${theme.colors.darkGray};
  `;
  const years = [];
  let index = 0;
  const sortedYears = Object.keys(props?.years)
  .sort(
    (a, b) => parseInt(b) - parseInt(a)
  )
  .reduce((accumulator: reportYears, key) => {
    // Adding a dash here because JS is auto sorting it numerically if it is numeric.
    accumulator[`${key}-`] = props?.years[key];
    return accumulator;
  }, {});

  for (const [key, value] of Object.entries(sortedYears)) {
    // convert object of reports into array of reports
    const reportsArray = Object.values(value);

    // sort by job id
    const sortedReports = reportsArray.sort((a, b) => {
      if (a.jobId < b.jobId) return 1;
      if (a.jobId > b.jobId) return -1;
      return 0;
    });

    const reportList = sortedReports.map((reportObj) => {
      return <ReportItem report={reportObj} clientId={props.clientId} isAdmin={props?.isAdmin} showDelete={props?.showDelete} />;
    });
    const yearAccordion = (
      <Accordion id={`${key}-accordion`}>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${key}-reports`}
          id={`${key}-summary`}
        >
          {/* Note: Removing the dash hack */}
          <Typography>{key.replace('-','')}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails id={`${key}-details`}>
          <Stack
            direction="column"
            divider={<StyledDivider orientation="horizontal" />}
            // spacing={2}
          >
            {reportList}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
    years.push(yearAccordion);
  }

  return <div>{years}</div>;
};
YearAccordion.propTypes = {
  isAdmin: PropTypes.bool,
  years: PropTypes.object,
  clientId: PropTypes.string,
  showDelete: PropTypes.func
};
export default YearAccordion;
