import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';

import AddUserDialogProps from './AddUserDialogProps';

const AddUserDialog = ({ onSubmit, onCancel }: AddUserDialogProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <TextField
          label="Email Address"
          margin="dense"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoFocus
          fullWidth
        />
        <TextField
          label="Password"
          margin="dense"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onSubmit(email, password)}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
