import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import Container from '@mui/material/Container';
import styled from '@emotion/styled';

import useFirebase from '../../hooks/useFirebase';
import useUsersByRole from '../../hooks/useUsersByRole';
import Role from '../../models/Role';
import SignIn from '../../components/SignIn';
import YearAccordion from '../../components/YearAccordion';
import Header from '../../components/Header';
import AppBar from '../../components/AppBar';
import '../../App.css';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import useDeleteReport from '../../hooks/useDeleteReport';

const StyledAppBar = styled(AppBar)`
  margin-bottom: 12px;
  margin-top: 12px;
`;

const Homepage = () => {
  const { isFirebaseInitialized, isSignedIn, currentUser, app } = useFirebase();
  const location = useLocation();
  const [clientIdParam, setClientIdParam] = useState<string | null>();
  const [clientId, setClientId] = useState<string>('');
  const { loading, usersByRole: admins } = useUsersByRole(Role.ADMIN);
  const [reports, setReports] = useState<any>();
  const [userRecord, setUserRecord] = useState<any>();
  const [client, setClient] = useState<any>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [reportToDelete, setReportToDelete] = useState<any>();

  // parse query parameters
  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      setClientIdParam(params.get('clientId'));
    }
  }, [location]);

  // determine which clientId to use
  useEffect(() => {
    if (!loading) {
      if (currentUser && admins[currentUser.uid]) {
        setIsAdmin(true);
      }
      if (clientIdParam && currentUser && admins[currentUser.uid]) {
        setClientId(clientIdParam);
      } else if (userRecord && userRecord?.clients) {
        // Get an array of the user's clientId from the keys
        const userClients = Object.keys(userRecord?.clients);
        if (userClients && userClients.length > 0) setClientId(userClients[0]);
      } else {
        setClientId('');
      }
    }
  }, [clientIdParam, loading, admins, currentUser, userRecord]);

  useEffect(() => {
    if (app && currentUser) {
      const db = getDatabase(app);
      if (currentUser?.uid) {
        // prettier-ignore
        const clientsByUser = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/users/${currentUser?.uid}`);
        onValue(clientsByUser, (snapshot) => {
          setUserRecord(snapshot.val());
        });
      }
    }
  }, [app, currentUser]);

  useEffect(() => {
    if (app && clientId) {
      const db = getDatabase(app);
      // prettier-ignore
      // Find first client record of the current logged in user
      const clientRef = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/clients/${clientId}`);
      onValue(clientRef, (snapshot) => {
        setClient(snapshot.val());
        // prettier-ignore
        const reportRef = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/reports/${clientId}`);
        onValue(reportRef, (snapshot) => {
          setReports(snapshot.val());
        });
      });
    }
  }, [app, clientId]);

  const { deleteReport } = useDeleteReport();

  const handleShowDelete = (report: any) => {
    setReportToDelete(report);
    setShowDelete(true);
  };

  const hideDelete = () => {
    setShowDelete(false);
    setReportToDelete(null);
  };

  const handleDeleteReport = async () => {
    await deleteReport(reportToDelete);
    hideDelete();
  };

  return (
    <div className="App">
      <Container maxWidth="lg">
        <Header />
        {isSignedIn && <StyledAppBar title={client?.companyName} showAdmin />}
        {/* only use firebase if firebase is initialized */}
        {isFirebaseInitialized && !isSignedIn && <SignIn />}
        {isSignedIn && clientId == '' && <div>Unable To Load Reports</div>}
        {reports && isSignedIn && (
          <YearAccordion years={reports} clientId={client?.clientId} isAdmin={isAdmin} showDelete={handleShowDelete} />
        )}
        <Dialog open={showDelete}>
          <DialogTitle>Delete Report Confirmation</DialogTitle>
          <DialogContent dividers>
            <Typography>Are you sure you wish to delete this report?</Typography>
            <Typography>{reportToDelete?.description}</Typography>
            <Typography>{reportToDelete?.packageDescription}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={hideDelete}>
              Cancel
            </Button>
            <Button onClick={handleDeleteReport}>Ok</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default Homepage;
