import { useEffect, useState } from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  User
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

import { useFirebase } from '../../components/FirebaseProvider/FirebaseProvider';

const useAuth = () => {
  const { app } = useFirebase();
  const navigate = useNavigate();

  const [user, setUser] = useState<User | null>();

  const logIn = async (email: string, password: string) => {
    if (app) {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
    }
  };

  const logOut = async () => {
    if (app) {
      const auth = getAuth();
      await signOut(auth).then(() => navigate('/'));
    }
  };

  useEffect(() => {
    if (app) {
      const auth = getAuth();
      auth.onAuthStateChanged((user) => setUser(user));
    }
  }, [app]);

  return { user, logIn, logOut };
};

export default useAuth;
