import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Button } from '@mui/material';
import styled from '@emotion/styled';
import _ from 'lodash';

import useClients from '../../hooks/useClients';
import useUsers from '../../hooks/useUsers';
import Client from '../../models/Client';
import User from '../../models/User';
import ClientList from '../../components/ClientList';
import UserDataGrid from '../../components/UserDataGrid';
import AppBar from '../../components/AppBar';
import AddClientDialog from '../../components/AddClientDialog';
import AddUserDialog from '../../components/AddUserDialog';
import useAddClient from '../../hooks/useAddClient';
import useAddUser from '../../hooks/useAddUser';

const PageContainer = styled(Container)`
  height: 100vh;
`;

const AdminGridContainer = styled(Grid)`
  height: calc(100% - 48px);
`;

const MaxHeightGrid = styled(Grid)`
  height: 100%;
`;

const ClientActionsGridItem = styled(Grid)`
  display: flex;
  justify-content: end;
  padding: 32px;
  gap: 12px;
`;

const UserDataGridItem = styled(Grid)`
  height: 100%;
  padding: 0 32px 32px 32px;
`;

const AdminPage = () => {
  const [showAddClientDialog, setShowAddClientDialog] = useState(false);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client>();
  const [selectedClientUsers, setSelectedClientUsers] = useState<[string, User][]>([]);
  const { addClient } = useAddClient();
  const { addUser } = useAddUser();
  const navigate = useNavigate();
  const { clients } = useClients();
  const { users } = useUsers();

  // select first client as default when page loads
  useEffect(() => {
    if (!selectedClient && clients && clients.length > 0) {
      setSelectedClient(clients[0]);
    }
  }, [clients, selectedClient]);

  // filter users when selecting a new client
  useEffect(() => {
    if (users && selectedClient) {
      setSelectedClientUsers(
        _.filter(users, ([_id, user]) => user.clients && _.includes(Object.keys(user.clients), selectedClient.clientId))
      );
    }
  }, [users, selectedClient]);

  return (
    <PageContainer>
      {showAddClientDialog && (
        <AddClientDialog
          onCancel={() => setShowAddClientDialog(false)}
          onSubmit={(companyName, companyKey) => {
            addClient(companyName, companyKey);
            setShowAddClientDialog(false);
          }}
        />
      )}
      {showAddUserDialog && (
        <AddUserDialog
          onCancel={() => setShowAddUserDialog(false)}
          onSubmit={(email, password) => {
            if (selectedClient) {
              addUser([selectedClient.clientId], email, password);
            }
            setShowAddUserDialog(false);
          }}
        />
      )}
      <AppBar />
      <AdminGridContainer container alignItems="stretch">
        <MaxHeightGrid item xs="auto">
          <ClientList
            clients={clients}
            selectedClientId={selectedClient?.clientId}
            onClientSelect={(client) => setSelectedClient(client)}
            onClientAdd={() => setShowAddClientDialog(true)}
          />
        </MaxHeightGrid>
        <Grid item xs>
          <MaxHeightGrid container direction="column" alignItems="stretch">
            {selectedClient && (
              <ClientActionsGridItem item xs="auto">
                <Button variant="contained" onClick={() => navigate(`/?clientId=${selectedClient.clientId}`)}>
                  Home Screen
                </Button>
                <Button variant="contained" onClick={() => navigate(`/addReport?clientId=${selectedClient.clientId}`)}>
                  Add Report
                </Button>
                <Button variant="contained" onClick={() => setShowAddUserDialog(true)}>
                  Add User
                </Button>
              </ClientActionsGridItem>
            )}
            <UserDataGridItem item xs>
              <UserDataGrid users={selectedClientUsers} />
            </UserDataGridItem>
          </MaxHeightGrid>
        </Grid>
      </AdminGridContainer>
    </PageContainer>
  );
};

export default AdminPage;
