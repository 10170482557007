import PeopleIcon from '@mui/icons-material/People';
import styled from '@emotion/styled';

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0.5;
`;

const NoUsersIcon = styled(PeopleIcon)`
  width: 2em;
  height: 2em;
`;

const NoRowsOverlay = () => {
  return (
    <OverlayContainer>
      <NoUsersIcon />
      <strong>No Users</strong>
    </OverlayContainer>
  );
};

export default NoRowsOverlay;
