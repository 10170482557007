import { CircularProgress, Typography } from '@mui/material';
import styled from '@emotion/styled';

import LoadingIndicatorProps from './LoadingIndicatorProps';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const LoadingIndicator = ({ message }: LoadingIndicatorProps) => {
  return (
    <Container>
      <CircularProgress />
      <Typography>{message}</Typography>
    </Container>
  );
};

export default LoadingIndicator;
