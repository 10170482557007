import { getDatabase, ref, set } from 'firebase/database';

import useFirebase from '../useFirebase';

const useEditUser = () => {
  const { app } = useFirebase();

  const toggleActive = (userId: string, clientId: string, value: boolean) => {
    const db = getDatabase(app);
    // prettier-ignore
    const userRef = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/users/${userId}/clients/${clientId}`);
    set(userRef, value);
  };

  return { toggleActive };
};

export default useEditUser;
