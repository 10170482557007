import axios from 'axios';

import useFirebase from '../useFirebase';

const useAddUser = () => {
  const { currentUser } = useFirebase();

  const addUser = async (clientIds: string[], email: string, password: string) => {
    const data = { clientIds, email, password };
    const token = await currentUser?.getIdToken(true).catch((error) => {
      // TODO - Error handling.
    });
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json'
    };

    // prettier-ignore
    // TODO - Move to ENV variable.
    axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/addUser`,
        data, { headers })
        .catch((error) => {
            // TODO - Error handling.
            console.error(error);
        });
  };

  return { addUser };
};

export default useAddUser;
