import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import 'firebase/compat/auth';
import { useLocation } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';
import { Stack } from '@mui/system';
import {
  Alert,
  Container,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Select,
  SelectChangeEvent,
  Snackbar
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import styled from '@emotion/styled';
import axios from 'axios';

import { formatDateForStorage } from '../../utils/dateFormatter';
import useFirebase from '../../hooks/useFirebase';
import Header from '../../components/Header';
import { client, report } from '../../types';
import '../../App.css';

const UploadFileLabel = styled.div`
  display: flex;
  align-items: center;
  min-height: 24px;
`;

const ReportAdd = () => {
  const { isFirebaseInitialized, currentUser, app } = useFirebase();
  const [clients, setClients] = useState<client[]>(new Array<client>());
  const [reports, setReports] = useState<any>({});
  const [reportObjects, setReportObjects] = useState<any>({});
  const [selectedClient, setSelectedClient] = useState<string>('');
  const [selectedReport, setSelectedReport] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [description, setDescription] = useState<string | undefined>();
  // prettier-ignore
  const [packageDescription, setPackageDescription] = useState<string | undefined>();
  const [jobId, setJobId] = useState<string | undefined>();
  const [file, setFile] = useState<File | undefined | null>();
  const location = useLocation();
  const [isError, setIsError] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);

  // parse query parameters
  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      const clientId = params.get('clientId');
      if (clientId) setSelectedClient(clientId);
    }
  }, [location]);

  useEffect(() => {
    if (app) {
      const db = getDatabase(app);
      const clientRef = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/clients/`);
      onValue(clientRef, (snapshot) => {
        const allClients = snapshot.val();
        setClients(allClients);
      });
    }
  }, [app]);

  useEffect(() => {
    if (selectedClient) {
      const db = getDatabase(app);
      const reportRef = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/reports/${selectedClient}`);
      onValue(reportRef, (snapshot) => {
        const rptsByYear = snapshot.val();
        setReports(rptsByYear);
        let flattenedReports = {};
        Object.values(rptsByYear).forEach((year: any) => {
          flattenedReports = { ...flattenedReports, ...year };
        });
        setReportObjects(flattenedReports);
      });
    }
  }, [app, selectedClient]);

  useEffect(() => {
    if (selectedReport) {
      const reportObj = reportObjects[selectedReport];
      setSelectedDate(new Date(reportObj.date) || null);
      setDescription(reportObj.description || null);
      setPackageDescription(reportObj.packageDescription || null);
      setJobId(selectedReport || null);
    }
  }, [app, reportObjects, selectedReport]);

  const handleSelectedClient = (event: SelectChangeEvent) => {
    setSelectedClient(event.target.value);
  };

  const handleSelectedReport = (event: SelectChangeEvent) => {
    setSelectedReport(event.target.value);
  };

  const handleDate = (newDate: Date | null, keyboardInput: string | undefined) => {
    if (newDate) {
      setSelectedDate(newDate);
    }
    if (keyboardInput) {
      // the function will return whatever is typed into the text field for the date picker.
      // Validate that it is a valid date before setting the component state.
      const timestamp = Date.parse(keyboardInput);
      if (!isNaN(timestamp)) {
        setSelectedDate(new Date(keyboardInput));
      }
    }
  };

  const handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handlePackageDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPackageDescription(event.target.value);
  };

  const handleJobId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setJobId(event.target.value);
  };
  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };
  if (!isFirebaseInitialized) {
    return (
      <div className="App">
        <Container maxWidth="lg">
          <Header />
          {/* only use firebase if firebase is initialized */}
          <CircularProgress />
        </Container>
      </div>
    );
  }
  const clientsArray = Object.values(clients).sort((a, b) => {
    if (a.companyName < b.companyName) {
      return -1;
    }
    if (a.companyName > b.companyName) {
      return 1;
    }
    return 0;
  });
  const clientOptions = clientsArray.map((client) => (
    <MenuItem value={client?.clientId}>{client?.companyName}</MenuItem>
  ));

  const reportOptions = Object.keys(reportObjects).map((rpt) => (
    <MenuItem key={rpt} value={rpt}>
      {rpt}
    </MenuItem>
  ));
  reportOptions.unshift(
    <MenuItem key={''} value={''}>
      {'New Report'}
    </MenuItem>
  );

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (file) {
      let formData = new FormData();
      const token = await currentUser?.getIdToken(/* forceRefresh */ true).catch(function (error) {
        // Handle error
      });
      const isUpdate = selectedReport ? true : false;
      formData.append('isUpdate', `${isUpdate}`);
      formData.append('jobId', `${jobId}`);
      formData.append('clientId', selectedClient);
      formData.append('filename', `${jobId}.pdf`);
      formData.append('description', `${description}`);
      formData.append('packageDescription', `${packageDescription}`);
      formData.append('date', formatDateForStorage(selectedDate));
      formData.append('upload', file); // Don't set to potential undefined.
      // TODO: make this configurable.
      const uploadUrl = `${process.env.REACT_APP_FUNCTIONS_URL}/uploadFile`;
      // const uploadUrl = 'http://127.0.0.1:5001/avian-canine/us-central1/app/uploadFile'
      axios
        .post(uploadUrl, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-type': 'multipart/form-data'
          }
        })
        .then((res) => {
          setIsError(false);
          setIsComplete(true);
          setJobId('');
          setDescription('');
          setPackageDescription('');
          setFile(null);
        })
        .catch((err) => {
          console.error(err);
          setIsError(true);
        });
    }
  };

  return (
    <div className="App">
      <Container maxWidth="lg">
        <Header />
        {clients && (
          <div>
            <Snackbar open={isError} autoHideDuration={6000} onClose={() => setIsError(false)}>
              <Alert severity="error" onClose={() => setIsError(false)}>
                There was an error uploading the report!
              </Alert>
            </Snackbar>
            <Snackbar open={isComplete} autoHideDuration={3000} onClose={() => setIsComplete(false)}>
              <Alert severity="success" onClose={() => setIsComplete(false)}>
                Report Added!
              </Alert>
            </Snackbar>
            <h2>Add Report</h2>
            <form onSubmit={submitHandler}>
              <Stack spacing={2}>
                <FormControl>
                  <InputLabel id="select-client-label">Select Client</InputLabel>
                  <Select
                    labelId="select-client-label"
                    id="select-client"
                    value={selectedClient}
                    label="Select Client"
                    onChange={handleSelectedClient}
                  >
                    {clientOptions}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel id="select-report-label">Select Report</InputLabel>
                  <Select
                    labelId="select-report-label"
                    id="select-report"
                    value={selectedReport}
                    label="Select Report to Update"
                    onChange={handleSelectedReport}
                  >
                    {reportOptions}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel shrink={jobId ? true : false} htmlFor="jobId">
                    Job ID
                  </InputLabel>
                  <OutlinedInput id="jobId" value={jobId} label="Job ID" onChange={handleJobId} />
                </FormControl>
                <DatePicker
                  label="Date"
                  value={selectedDate}
                  onChange={handleDate}
                  renderInput={(params) => <TextField {...params} />}
                />
                <FormControl>
                  <InputLabel shrink={description ? true : false} htmlFor="description">
                    Description
                  </InputLabel>
                  <OutlinedInput
                    id="description"
                    value={description}
                    label="Description"
                    onChange={handleDescription}
                  />
                </FormControl>
                <FormControl>
                  <InputLabel shrink={packageDescription ? true : false} htmlFor="packageDescription">
                    Package Description
                  </InputLabel>
                  <OutlinedInput
                    id="packageDescription"
                    value={packageDescription}
                    label="Package Description"
                    onChange={handlePackageDescription}
                  />
                </FormControl>
                <Button variant="outlined" component="label">
                  Upload Report File
                  <input hidden accept="application/pdf" type="file" onChange={handleFile} />
                </Button>
                <UploadFileLabel>
                  {file && <UploadFileIcon />}
                  {file?.name}
                </UploadFileLabel>
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Stack>
            </form>
          </div>
        )}
      </Container>
    </div>
  );
};

export default ReportAdd;
