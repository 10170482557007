import { createContext } from 'react';
import IFirebaseContext from './IFirebaseContext';

const defaultValue = {
  loading: true,
  isFirebaseInitialized: false,
  isSignedIn: false
};

const FirebaseContext = createContext<IFirebaseContext>(defaultValue);

export default FirebaseContext;
