import { useState, useEffect, RefObject } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

import useFirebase from '../useFirebase';

const useDownloadFile = (clientId: string, fileName: string, linkRef: RefObject<HTMLAnchorElement>) => {
  const [downloadUrl, setDownloadUrl] = useState<string>();
  const { currentUser } = useFirebase();

  // fetch file blob
  const downloadFile = async () => {
    // return early if we already have the blob
    if (downloadUrl) return;

    const token = await currentUser?.getIdToken(true).catch((error) => {
      // TODO - Error handling.
    });
    const options: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer'
    };

    axios
      .get(`${process.env.REACT_APP_FUNCTIONS_URL}/reports/${clientId}/${fileName}`, options)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        if (linkRef.current) {
          setDownloadUrl(url);
        }
      })
      .catch((error) => console.error(error));
  };

  // download file after link is fetched
  useEffect(() => {
    if (linkRef.current && downloadUrl) {
      linkRef.current.href = downloadUrl;
      linkRef.current.click();
    }
    return () => {
      // release blob memory on unmount
      if (downloadUrl) window.URL.revokeObjectURL(downloadUrl);
    };
  }, [fileName, linkRef, downloadUrl]);

  return { downloadFile };
};

export default useDownloadFile;
