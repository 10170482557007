import { Navigate } from 'react-router-dom';

import Role from '../../models/Role';
import useFirebase from '../../hooks/useFirebase';
import useUsersByRole from '../../hooks/useUsersByRole';
import LoadingIndicator from '../LoadingIndicator';

const withAuthorization = (Page: () => JSX.Element, role: Role) => () => {
  const { loading: loadingFirebase, currentUser } = useFirebase();
  const { loading: loadingAdmins, usersByRole: admins } = useUsersByRole(role);

  const isAdmin = currentUser && admins[currentUser.uid];

  return (
    <>
      {loadingAdmins && <LoadingIndicator message="Authenticating..." />}
      {!loadingAdmins && isAdmin && <Page />}
      {((!loadingFirebase && !currentUser) || (!loadingAdmins && !isAdmin)) && (
        <Navigate replace to="/" />
      )}
    </>
  );
};

export default withAuthorization;
