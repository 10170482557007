import { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import _ from 'lodash';

import useFirebase from '../useFirebase';
import Client from '../../models/Client';

const useClients = () => {
  const { app } = useFirebase();
  const [clients, setClients] = useState<Client[]>();

  useEffect(() => {
    if (app) {
      const db = getDatabase(app);
      // prettier-ignore
      const clientsRef = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/clients`);
      onValue(clientsRef, (snapshot) => {
        setClients(
          _.sortBy(Object.values<Client>(snapshot.val()), ['companyName'])
        );
      });
    }
  }, [app]);

  return { clients };
};

export default useClients;
