import { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, get } from 'firebase/database';

import useFirebase from '../useFirebase';

const useUsersByRole = (role: string) => {
  const [loading, setLoading] = useState(true);
  const [usersByRole, setUsersByRole] = useState<{ [key: string]: boolean }>(
    {}
  );
  const { app } = useFirebase();

  useEffect(() => {
    if (app && role) {
      const db = getDatabase(app);
      // prettier-ignore
      const usersByRoleRef = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/usersByRole/` + role);
      get(usersByRoleRef)
        .then((snapshot) => {
          setUsersByRole(snapshot.val());
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
      onValue(usersByRoleRef, (snapshot) => {
        setUsersByRole(snapshot.val());
        setLoading(false);
      });
    }
  }, [app, role]);

  return { loading, usersByRole };
};

export default useUsersByRole;
