import { useContext, useEffect, useState } from 'react';
import { FirebaseApp } from '@firebase/app';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import FirebaseProviderProps from './FirebaseProviderProps';
import FirebaseContext from './FirebaseContext';

const FirebaseProvider = ({ children }: FirebaseProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [isFirebaseInitialized, setIsFirebaseInitialized] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [app, setApp] = useState<FirebaseApp | undefined>();

  useEffect(() => {
    setApp(
      firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID
      })
    );
    setIsFirebaseInitialized(true);
  }, []);

  useEffect(() => {
    if (isFirebaseInitialized) {
      const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
        setLoading(false);
        setIsSignedIn(!!user);
      });
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }
  }, [isFirebaseInitialized]);

  const currentUser = isFirebaseInitialized ? firebase.auth().currentUser : undefined;

  return (
    <FirebaseContext.Provider
      value={{
        loading,
        isFirebaseInitialized,
        isSignedIn,
        currentUser,
        app
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider.');
  }
  return context;
};

export default FirebaseProvider;
export { useFirebase, FirebaseContext };

