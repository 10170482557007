import { useRef } from 'react';
import { Grid, Typography, Link, Button } from '@mui/material';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportItemProps from './ReportItemProps';
import { formatDate } from '../../utils/dateFormatter';
import useDownloadFile from '../../hooks/useDownloadFile';

const DownloadLink = styled(Link)`
  cursor: pointer;
`;

const RightText = styled(Typography)`
  text-align: right;
`;

const ReportItem = ({ clientId, report, isAdmin, showDelete }: ReportItemProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { downloadFile } = useDownloadFile(clientId, report.file, linkRef);
  console.log(report);
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Typography>
          <DownloadLink ref={linkRef} onClick={downloadFile} title={report.jobId} target="_blank" rel="noopener">
            {report.jobId}
          </DownloadLink>
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography> {report.description}</Typography>
      </Grid>
      <Grid item xs={3}>
        <RightText>{formatDate(report.date)}</RightText>
      </Grid>
      <Grid item xs={2}>
        <Typography>{report.packageDescription}</Typography>
      </Grid>
      {isAdmin
        ?<Grid item xs={9}>
          <RightText>{report.enteredBy}</RightText>
        </Grid>
        :<Grid item xs={10}>
          <RightText>{report.enteredBy}</RightText>
        </Grid>
      }
      {isAdmin && (
        <Grid item xs={1}>
        <Button onClick={() => showDelete(report)}><DeleteIcon /> </Button>
      </Grid>
      )}

    </Grid>
  );
};

/**
 *  date: "2011-12-15T05:59:05.184Z",
        description: "47 radian M Lighter",
        enteredBy: "CONFERENCE",
        file: "LOJHA92-30.pdf",
        jobId: "LOJHA92-30",
        packageDescription: "15 second VIAL"
 */
ReportItem.propTypes = {
  isAdmin: PropTypes.bool,
  showDelete: PropTypes.func,
  report: PropTypes.shape({
    date: PropTypes.string,
    description: PropTypes.string,
    enteredBy: PropTypes.string,
    file: PropTypes.string,
    jobId: PropTypes.string,
    packageDescription: PropTypes.string
  }),
  clientId: PropTypes.string
};

export default ReportItem;
