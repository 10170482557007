import { useState, useEffect } from 'react';
import { Checkbox } from '@mui/material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';

import UserDataGridProps from './UserDataGridProps';
import useEditUser from '../../hooks/useEditUser';
import NoRowsOverlay from './NoRowsOverlay';
import UserRow from './UserRow';

const UserDataGrid = ({ users }: UserDataGridProps) => {
  const [userRows, setUserRows] = useState<UserRow[]>([]);
  const { toggleActive } = useEditUser();

  useEffect(() => {
    if (users) {
      setUserRows(
        users.map(([id, user]) => {
          return {
            id,
            email: user.email,
            active: Object.values(user.clients)[0],
            data: user
          };
        })
      );
    }
  }, [users]);

  const UserColumns = [
    { field: 'email', headerName: 'Email', flex: 1 },
    {
      field: 'active',
      headerName: 'Active',
      renderCell: (params: GridCellParams<boolean>) => {
        return (
          <Checkbox
            checked={params.value}
            onChange={() => {
              toggleActive(
                params.row.id,
                Object.keys(params.row.data.clients)[0],
                !params.value
              );
            }}
          />
        );
      }
    }
  ];

  return (
    <DataGrid
      columns={UserColumns}
      rows={userRows}
      sx={{ minHeight: 300 }}
      components={{ NoRowsOverlay }}
    />
  );
};

export default UserDataGrid;
