/**
 * Date formatting library so that all date handlig can be processed in a standard way.
 */

import { format } from 'date-fns'

const formatDate = (dateString:string) => {
    const dateObj = new Date(dateString);
    return format(dateObj, 'EEEE, MMMM d, yyyy')
}

const formatDateForStorage = (theDate: Date) => {
    return theDate.toISOString();
}

export {
    formatDate,
    formatDateForStorage
}