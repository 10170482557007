import { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import _ from 'lodash';

import useFirebase from '../useFirebase';
import User from '../../models/User';

const useUsers = () => {
  const { app } = useFirebase();
  const [users, setUsers] = useState<[string, User][]>();

  useEffect(() => {
    if (app) {
      const db = getDatabase(app);
      const usersRef = ref(db, `${process.env.REACT_APP_FIREBASE_ENV}/users`);
      onValue(usersRef, (snapshot) => {
        if (snapshot.val()) {
          setUsers(_.sortBy(Object.entries<User>(snapshot.val()), ['email']));
        } else {
          setUsers([]);
        }
      });
    }
  }, [app]);

  return { users };
};

export default useUsers;
